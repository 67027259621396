import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    window.scrollTo(0, 0);
    return (
        <>
            <main>
                <section id="heading">
                    <div id="heading-carousel" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="heading-bg heading-bg-1 d-flex align-items-center">
                                    <div className="container py-5">
                                        <div className="row py-4 px-3 px-sm-0">
                                            <div className="col-sm-11 col-md-8">
                                                <h1 className='main-heading text-white display-5 fw-bolder'>Your Trusted Source for Quality Trade and Supply.</h1>
                                                <h3 className='heading-quality pt-sm-4 pt-2 fs-4 text-white'>Essential Elements, Efficient Supply Chain, Major Source!</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="heading-bg heading-bg-2 d-flex align-items-center">
                                    <div className="container py-5">
                                        <div className="row py-4 px-3 px-sm-0">
                                            <div className="col-sm-11 col-md-8">
                                                <h1 className='main-heading text-white display-5 fw-bolder'>Gateway to Industrial Excellence.</h1>
                                                <h3 className='heading-quality pt-sm-4 pt-2 fs-4 text-white'>Coal, Minerals, Polymers and More!</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="heading-bg heading-bg-3 d-flex align-items-center">
                                    <div className="container py-5">
                                        <div className="row py-4 px-3 px-sm-0">
                                            <div className="col-sm-11 col-md-8">
                                                <h1 className='main-heading text-white display-5 fw-bolder'>Powering Your Progress with Premium Products.</h1>
                                                <h3 className='heading-quality pt-sm-4 pt-2 fs-4 text-white'>Quality, Value, Excellence</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#heading-carousel" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#heading-carousel" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </section>
                <section id="profile" className="d-flex align-items-center">
                    <div className="container py-5">
                        <div className="row px-4 px-sm-0">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 col-lg-11 profile-img-col">
                                        <img src="assets/img/profile img 1.webp" alt="Orion Energy" className='w-100 profile-img' />
                                    </div>
                                    <div className="col-12 col-lg-11 profile-img-col d-none d-md-block">
                                        <img src="assets/img/profile img 2.webp" alt="Orion Energy" className='w-100 profile-img' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-center p-0 px-lg-3 px-xl-5">
                                <div className='px-md-2 px-lg-4'>
                                    <h1 className='display-4 fw-bolder text-white pb-2 profile-heading'>Company Profile</h1>
                                    <p className='fs-5 text-white pb-4 profile-para'>Our journey began in 2019 with the vision to provide high-quality products to various industries in Pakistan. Orion Energy PVT Ltd is trading coal, petrochemicals and minerals.</p>
                                    <Link to="/about" className='text-decoration-none text-white profile-learn-btn px-4 py-2 fs-5 fw-bold rounded-3' >Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="services" className='d-flex align-items-center'>
                    <div className="container py-5">
                        <div className="row py-5">
                            <div className="col-sm-6">
                                <h1 className='services-heading display-4 fw-bold text-white'>Our <span className='d-block'>Products</span></h1>
                                <p className='text-white services-product-para w-50'>Orion Energy offers a diverse range of products and services to cater to the energy and
                                    industrial sectors. We deliver long-term value to our valuable customers, from highquality coal to petrochemicals and minerals.</p>
                            </div>
                            <div className="col-sm-6 col-lg-4 ms-auto me-lg-5">
                                <table className='text-white'>
                                    <tbody>
                                        <tr>
                                            <td className='d-flex fs-2 fw-bolder pe-3'>01</td>
                                            <td>
                                                <h3 className='services-subheading fs-2 mb-0'>Coal</h3>
                                                <p className='services-subpara pb-4'>Powering your operations with premium coal solutions. We deliver different types of coal for various industries and sectors.<br /> <Link to="/coal" className='text-decoration-none'>Learn more...</Link> </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='d-flex fs-2 fw-bolder pe-3'>02</td>
                                            <td>
                                                <h3 className='services-subheading fs-2 mb-0'>Polymers and Chemicals</h3>
                                                <p className='services-subpara pb-4'>We pioneer the efficient supply of polymers and chemicals, providing industry-tailored solutions.<br /> <Link to="/polymers-and-chemicals" className='text-decoration-none'>Learn more...</Link></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='d-flex fs-2 fw-bolder pe-3'>03</td>
                                            <td>
                                                <h3 className='services-subheading fs-2 mb-0'>Minerals</h3>
                                                <p className='services-subpara pb-4'>Ensuring the trade of a vast range of minerals for optimal usage in the dynamic industrial sectors.<br /> <Link to="/minerals" className='text-decoration-none'>Learn more...</Link></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="clients">
                    <div className="container py-5">
                        <div className="row py-3">
                            <div className="col-10 m-auto">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className='clients-heading text-black display-4 fw-bolder pb-2'>Clientele: </h1>
                                    </div>
                                    <div className="col-xl-9">
                                        <p className='clients-para text-black'>Since our inception, we've consistently delivered dependable service to our valued clientele. We take pride in being the reliable supplier of different natural resources for the following esteemed corporations:</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10 m-auto py-5">
                                <div className="row px-3 px-sm-0">
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 1.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 2.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 3.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 4.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 5.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 6.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 7.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 8.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 9.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/svg/clentele 1.svg" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/svg/clentele 3.svg" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/svg/clentele 4.svg" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/img/clientele 10.webp" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                        <img src="assets/svg/clentele 6.svg" alt="Orion Energy" className='m-auto w-75 mb-4' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="testimonial">
                    <div className="container py-4">
                        <div className="row">
                            <div className="col-12">
                                <div id="testimonial-crouseal" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row">
                                                <div className="col-10 col-lg-7 m-auto py-5">
                                                    <h5 className='text-center'><i className="fa-solid fa-quote-left display-4"></i></h5>
                                                    <p className='text-center text-black'>"I've been sourcing polymers from Orion Energy for years, and their commitment to quality is unmatched. The PPH and HDPE polymers have consistently exceeded our expectations, proving vital in our manufacturing processes. Orion's attention to detail in providing top-notch products is commendable."</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-10 col-lg-7 m-auto py-5">
                                                    <h5 className='text-center'><i className="fa-solid fa-quote-left display-4"></i></h5>
                                                    <p className='text-center text-black'>"As a construction company, the quality of materials is non-negotiable. Orion Energy has been our go-to supplier for minerals like Granite and Marble. The Granite, in particular, has proven to be durable and aesthetically pleasing. Their professionalism and prompt delivery of materials make them an invaluable partner in our projects."</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-10 col-lg-7 m-auto py-5">
                                                    <h5 className='text-center'><i className="fa-solid fa-quote-left display-4"></i></h5>
                                                    <p className='text-center text-black'>"We've been sourcing Indonesian Coal from Orion Energy, and it has proven to be a game-changer for our energy-intensive operations. The low ash content and superior calorific value of their coal have significantly improved our operational efficiency. The seamless supply chain and their dedication to customer satisfaction make Orion Energy our preferred partner."</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#testimonial-crouseal" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#testimonial-crouseal" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Home