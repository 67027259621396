import React from 'react'

function PreLoader() {
    setTimeout(() => {
        let loader = document.getElementById("pre-loader");
        loader.style.visibility = "hidden";
    }, 4700);
    return (
        <div className="preloader-box d-flex justify-content-center align-items-center position-fixed" id='pre-loader'>
            <img src="assets/img/orion loader.gif" alt="ORION ENERGY" className='orion-loader' />
        </div>
    )
}

export default PreLoader
