import React from 'react'
import { Link } from 'react-router-dom';

function About() {
    window.scrollTo(0, 0);
    return (
        <>
            <section id="about-heading" className='d-flex align-items-center'>
                <div className="container py-5">
                    <div className="row py-5 px-3 px-sm-0">
                        <div className='about-heading-content-box my-5'>
                            <h1 className='about-heading-head'>About Company</h1>
                            <p className='about-heading-para'>From the most remote corners of the earth we provide quality resources to fuel various sectors</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about-detail" className='pt-5 pb-2'>
                <div className="container pb-5">
                    <div className="row py-3 py-sm-5">
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="row">
                                <div className="col-md-6 d-none d-lg-block">
                                    <img src="assets/img/about-img-2.webp" alt="Orion Energy" className='w-100 position-relative about-detail-img-1 rounded-3' />
                                </div>
                                <div className="col-lg-6 ">
                                    <img src="assets/img/about-img-1.webp" alt="Orion Energy" className='w-100 position-relative about-detail-img-2 rounded-3 mb-4 mb-md-0 px-3 px-sm-0' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 ms-auto">
                            <div className="about-content px-3 px-sm-0">
                                <h1 className='about-heading mb-4'>We’re the best in trade and supply of natural resources.</h1>
                                <p className='about-para pb-4 pb-sm-5'>Our journey began in 2019 with the vision to provide high-quality
                                    products to various industries in Pakistan. Orion Energy PVT Ltd is
                                    trading coal, petrochemicals and minerals. We are supplying to
                                    major sectors in Pakistan including cement, energy, construction,
                                    Packaging, and many others. Our purpose is to contribute in the
                                    industrial supply chain through quality resources. Today, we have
                                    become a reliable and sustainable supplier committed to quality
                                    and excellence.</p>
                                <Link to="/contact" className='text-decoration-none text-black'>
                                    <button className="btn px-4 py-2 rounded-5 about-btn text-white">Contact Us</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section id="vision">
                <div className="container py-5">
                    <div className="row py-3">
                        <div className="col-sm-6 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
                            <div className="card" style={{ width: '22rem' }}>
                                <div className="card-body text-center rounded-3">
                                    <div className="vision-img">
                                        <img src="assets/svg/mission.svg" alt="Orion Energy" className='w-25 mb-3' />
                                    </div>
                                    <h5 className="card-title mb-3 fs-4 fw-bold">Mission</h5>
                                    <p>
                                        To be a trusted leader in importing and trading
                                        of different products, providing sustainable
                                        solutions and serving our customers with
                                        integrity.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
                            <div className="card" style={{ width: '22rem' }}>
                                <div className="card-body text-center rounded-3">
                                    <div className="vision-img">
                                        <img src="assets/svg/vision.svg" alt="Orion Energy" className='w-25 mb-3' />
                                    </div>
                                    <h5 className="card-title mb-3 fs-4 fw-bold">Vision </h5>
                                    <p>To shape the future of energy and
                                        industrial and various other
                                        sectors through excellence,
                                        sustainability, and global
                                        partnerships.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
                            <div className="card" style={{ width: '22rem' }}>
                                <div className="card-body text-center rounded-3">
                                    <div className="vision-img">
                                        <img src="assets/svg/value.svg" alt="Orion Energy" className='w-25 mb-3' />
                                    </div>
                                    <h5 className="card-title mb-3 fs-4 fw-bold">Value</h5>
                                    <p>Integrity is our priority , quality is our
                                        responsibility, and excellence is our
                                        aim. We prioritize honesty, quality,
                                        and continuous improvement,
                                        driving Orion Energy's success and
                                        positive impact.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="why-us">
                <h1 className='text-center why-heading pt-3 pb-1'>Company Values</h1>
                <p className='text-center why-us-para'>At Orion Energy, our core values form the foundation of our company's culture and
                    operations. These values include:</p>
                <div className="why-bg">
                    <div className="container">
                        <div className="row d-flex justify-content-around">
                            <div className="col-md-5 py-4">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="why-bg-icon-box d-flex justify-content-center align-items-center py-4 rounded-5">
                                            <img src="assets/svg/integrity.svg" alt="Orion Energy" className='w-50' />
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <h4 className='fw-bold why-us-box-heading'>Integrity</h4>
                                        <p className='why-us-box-para mb-0'>We are unwavering in our
                                            commitment to honesty,
                                            transparency, and ethical
                                            business practices.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 py-4">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="why-bg-icon-box d-flex justify-content-center align-items-center py-4 rounded-5">
                                            <img src="assets/svg/sustainability.svg" alt="Orion Energy" className='w-50' />
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <h4 className='fw-bold why-us-box-heading'>Sustainability</h4>
                                        <p className='why-us-box-para mb-0'>We prioritize environmental
                                            responsibility in our operations,
                                            sourcing, and supply chain
                                            management.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 py-4">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="why-bg-icon-box d-flex justify-content-center align-items-center py-4 rounded-5">
                                            <img src="assets/svg/excelence.svg" alt="Orion Energy" className='w-50' />
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <h4 className='fw-bold why-us-box-heading'>Excellence</h4>
                                        <p className='why-us-box-para mb-0'>Quality is our utmost priority, and
                                            we continuously strive for
                                            excellence in all aspects of our
                                            business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 py-4">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="why-bg-icon-box d-flex justify-content-center align-items-center py-4 rounded-5">
                                            <img src="assets/svg/innovate.svg" alt="Orion Energy" className='w-50' />
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <h4 className='fw-bold why-us-box-heading'>Innovation</h4>
                                        <p className='why-us-box-para mb-0'>We foster a culture of innovation
                                            that drives us to continually seek
                                            new and improved solutions. With
                                            creative thinking, we remain at the
                                            forefront of our industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="company-ceo-profile">
                <div className="container">
                    <div className="row py-5 my-4">
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                            <img src="assets/img/omais khattak profile.webp" alt="Orion Energy" className='w-50 rounded-circle company-ceo-img mb-4' />
                        </div>
                        <div className="col-md-8 px-4 px-md-0">
                            <h2 className='fw-bold mb-0'>M. OMAIS KHATTAK</h2>
                            <h4>CEO</h4>
                            <p className='company-profile-para pe-md-5'>CEO at Khattak Sons Private Limited and Orion Energy Pvt. Ltd. Business
                                Professional having 20+ years experience in International Trade & Business
                                Development. Mr. Omais provides strategic direction and visionary leadership
                                to Orion Energy. An accomplished professional with diverse experience, he excels in
                                establishing alternative fuel facilities for NGVs. He possesses strong team
                                leadership, behavioral management, and conflict resolution skills.<br />
                                Additionally, he brings expertise in business administration, collaborating
                                with boards, cross-functional teams, and vendors to ensure seamless daily
                                operations, secure equipment and suppliers, and adhere to administrative
                                policies and regulations.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About