let scrollNext = (e) => {
    let cardCrouseal = e.target.parentNode.parentNode.querySelector(".card-crouseal");
    let col = cardCrouseal.querySelector(".card-box").parentNode.offsetWidth;
    cardCrouseal.scrollLeft += col;
}
let scrollprev = (e) => {
    let cardCrouseal = e.target.parentNode.parentNode.querySelector(".card-crouseal");
    let col = cardCrouseal.querySelector(".card-box").parentNode.offsetWidth;
    cardCrouseal.scrollLeft -= col;
}
let closeNav = (e) => {
    let navbarBtn = document.querySelector("button.navbar-toggler");
    navbarBtn.click();
}
module.exports = { scrollNext, scrollprev, closeNav }