import React from 'react'
import { Link } from 'react-router-dom';
import { closeNav } from './script';

function Header() {
    return (
        <header id='head'>
            <nav className="navbar navbar-expand-lg bg-body-tertiary position-absolute">
                <div className="container">
                    <a className="navbar-brand" href="/"><img src="assets/img/orion logo.webp" alt="Orion Energy" className='orion-logo' /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto px-3 px-sm-0">
                            <li className="nav-item">
                                <Link className="nav-link active text-white nav-link" aria-current="page" to="/" onClick={closeNav}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white nav-link" to="/about" onClick={closeNav}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white nav-link" to="/contact" onClick={closeNav}>Contact</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle text-white nav-link" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Products
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item text-white nav-link" to="/coal" onClick={closeNav}>Coal</Link></li>
                                    <li><Link className="dropdown-item text-white nav-link" to="/polymers-and-chemicals" onClick={closeNav}>Polymers and Chemicals</Link></li>
                                    <li><Link className="dropdown-item text-white nav-link" to="/minerals" onClick={closeNav}>Minerals</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header