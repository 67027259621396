import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <footer id='foot'>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-4 col-4 px-3">
                            <img src="assets/img/orion logo.webp" alt="Orion Energy" className='foot-logo pb-4' />
                            <p className='foot-para'>Orion Energy is your one-stop destination for the supply and sourcing of various commodities with guaranteed specifications and quality. Our purpose is to contribute in the industrial supply chain through quality resources.</p>
                        </div>
                        <div className="col-6 col-md-4 text-center footer-nav-col">
                            <h2 className='pb-2 foot-nav-heading'>Our Pages</h2>
                            <ul className='list-unstyled'>
                                <li className='pb-2'><Link to="/" className='text-decoration-none text-black foot-nav position-relative'>Home</Link></li>
                                <li className='pb-2'><Link to="/about" className='text-decoration-none text-black foot-nav position-relative'>About</Link></li>
                                <li className='pb-2'><Link to="/contact" className='text-decoration-none text-black foot-nav position-relative'>Contact</Link></li>
                                <li className='pb-2'><Link to="/coal" className='text-decoration-none text-black foot-nav position-relative'>Coal</Link></li>
                                <li className='pb-2'><Link to="/polymers-and-chemicals" className='text-decoration-none text-black foot-nav position-relative'>Polymers and Chemicals</Link></li>
                                <li className='pb-2'><Link to="/minerals" className='text-decoration-none text-black foot-nav position-relative'>Mineral</Link></li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 detail-col">
                            <h2 className='foot-detail-heading pb-2'>Our Details</h2>
                            <h4 className='fs-5'><i className="fa-solid fa-location-dot"></i> Mailing Address</h4>
                            <p className='text-black'>Plot # 298, Street # 03, I-9/3 Islamabad, Islamabad</p>
                            <h4 className='fs-5'><i className="fa-solid fa-phone"></i> Phone Number</h4>
                            <p className='text-black'>+92 300 0202256</p>
                            <h4 className='fs-5'><i className="fa-solid fa-envelope"></i> Email Address</h4>
                            <p className='text-black'>contact@orionenergy.com.pk</p>
                        </div>
                    </div>
                </div>
            </footer>
            <section id="powered">
                <h4 className='text-center powered-heading mb-0 py-3'>&copy; Orion Energy 2023-2024 | All Right Reserved | Designed By <a href='https://www.linkedin.com/company/creactiveinc/' target='_blank' rel="noreferrer" className='text-black '>Creactive</a></h4>
            </section>
        </>

    )
}

export default Footer