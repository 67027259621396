import React from 'react'

function Contact() {
    window.scrollTo(0, 0);
    return (
        <>
            <h5 className='contact-top-heading text-center px-3'>Our future plans are centered on continued expansion and diversification
                in the energy, petrochemical, and mineral sectors.<br />
                <span className='fw-bold'>Reiterate our contact information for easy reference:</span>
            </h5>
            <section id="contact">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-10 m-auto contact-col">
                            <h1 className="contact-heading fw-bold fs-1 text-center py-3">Contact and Locate Us</h1>
                            <div className="row py-5">
                                <div className="col-md-6">
                                    <h4 className='fs-5'><i className="fa-solid fa-location-dot"></i> Mailing Address</h4>
                                    <p className='text-black contact-detail'>Plot # 298, Street # 03, I-9/3 Islamabad, Islamabad</p>
                                    <h4 className='fs-5'><i className="fa-solid fa-phone"></i> Phone Number</h4>
                                    <p className='text-black contact-detail mb-0'>+92 300 0202256</p>
                                    <p className='text-black contact-detail'>051 6122117</p>
                                    <h4 className='fs-5'><i className="fa-solid fa-envelope"></i> Email Address</h4>
                                    <p className='text-black contact-detail'>contact@orionenergy.com.pk</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="iframe-box mt-3 mt-md-0">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d207.54692080245206!2d73.05950198135227!3d33.6636201224664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df951031463173%3A0x85c3c18ef4076ff8!2sVisionbotix!5e0!3m2!1sen!2s!4v1693906246951!5m2!1sen!2s" width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Orion Location'></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact
