import React from 'react';

function Coal() {
    window.scrollTo(0, 0);
    return (
        <>
            <div>
                <section id="about-heading" className='d-flex align-items-center coal-cover'>
                    <div className="container py-5">
                        <div className="row py-5 px-3 px-sm-0">
                            <div className='about-heading-content-box my-5'>
                                <h1 className='about-heading-head'>COAL</h1>
                                <p className='about-heading-para'>Ignite your energy needs with our premium coal selection – forging a powerful foundation for various industrial Operations.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="coal-detail">
                    <div className="container py-5">
                        <div className="row py-5 px-3 px-sm-0">
                            <div className="col-md-5 d-flex align-items-center mb-4 mb-md-0">
                                <div className="coal-img-box w-75 m-auto">
                                    <img src="assets/img/coal-detail.webp" alt="Orion Energy" className='w-100 rounded-3 coal-detail-img' />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h3 className='coal-detail-heading mb-3'>About Coal</h3>
                                <p className='coal-detail-para'>Orion energy is your premier source for exceptional coal varieties sourced from Afghanistan, South Africa, and Indonesia. Our commitment to quality ensures a diverse range, spanning from medium to high-grade coal. Mined from the rich reservoirs of these resource-abundant nations, our coal guarantees optimal usage across various industrial applications. Explore the unique attributes of Afghan Coal, South African Coal, and Indonesian Coal, each boasting distinctive qualities.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="coal-type">
                    <h1 className='text-center coal-type-heading'>Type of Coal We provide</h1>
                    <div className="container py-5">
                        <div className="row py-3">
                            <div className="col-sm-6 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/size coal.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body text-center">
                                        <h5 className="card-title mb-3">Afghan Coal</h5>
                                        <p>Afghan Coal, available in
                                            sizes ranging from 0mm to
                                            50mm, is prized for its high
                                            calorific value, making it an
                                            ideal choice for industries
                                            requiring consistent, high-quality coal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/imported coal.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body text-center">
                                        <h5 className="card-title mb-3">Indonesian Coal</h5>
                                        <p>Indonesian Coal, offered in
                                            various sizes, is known for its
                                            superior calorific value and
                                            low ash content, ensuring
                                            efficient and clean energy for
                                            a range of industries.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Afghan Coal.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body text-center">
                                        <h5 className="card-title mb-3">South African Coal</h5>
                                        <p>South African Coal, available
                                            in diverse sizes, stands out
                                            for its excellent quality, with
                                            high energy content and
                                            minimal impurities, making it
                                            a top choice for various
                                            industrial applications.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Coal
