import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Coal from './components/Coal';
import petrochemical from './components/petrochemical';
import PreLoader from './components/PreLoader';
import Minirals from './components/minirals';

function App() {
  return (
    <div className="App">
      <Router>
        <PreLoader />
        <Header />
        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/about' Component={About} />
          <Route path='/contact' Component={Contact} />
          <Route path='/coal' Component={Coal} />
          <Route path='/polymers-and-chemicals' Component={petrochemical} />
          <Route path='/minerals' Component={Minirals} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
