import React from 'react'

function petrochemical() {
    window.scrollTo(0, 0);
    return (
        <>
            <div>
                <section id="about-heading" className='d-flex align-items-center petrochemical-cover'>
                    <div className="container py-5">
                        <div className="row py-5 px-3 px-sm-0">
                            <div className='about-heading-content-box my-5'>
                                <h1 className='about-heading-head'>Polymers and Chemicals</h1>
                                <p className='about-heading-para'>Vast array of meticulously crafted polymers, each designed to meet the stringent standards of modern applications.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="coal-detail">
                    <div className="container py-5">
                        <div className="row py-5 px-3 px-sm-0">
                            <div className="col-md-5 d-flex align-items-center mb-4 mb-md-0">
                                <div className="coal-img-box w-75 m-auto">
                                    <img src="assets/img/polymers main.webp" alt="Orion Energy" className='w-100 rounded-3 coal-detail-img' />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h3 className='coal-detail-heading mb-3'>About Polymers and Chemicals</h3>
                                <p className='coal-detail-para'>At Orion Energy, we ensure an efficient polymer and chemical distribution. We help industries to revolutionize by offering the most convenient, effective, and competitive services to our customers. As the major trader and distributor, we bring you a wide range of high-quality products from top brands and leading producers. Our supply chain of quality products is only possible due to our diversified and global supplier base. Avail specialized product supply tailored to your company's needs, ensuring the best opportunities in the market.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="coal-type">
                    <h1 className='text-center coal-type-heading px-3 px-sm-0'>Polymers we Provide</h1>
                    <div className="container py-5">
                        <div className="row py-3 px-3 px-sm-0">
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/pprc.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">PPRC</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/pph.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">PPH</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/ppIn.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">PP Injections</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/ldp.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">LDPE</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/lldp.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">LLDPE</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/hdp.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">HDPE</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/pvc.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">PVC</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/abs.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">ABS</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="coal-type">
                    <h1 className='text-center coal-type-heading px-3 px-sm-0'>Chemicals we Provide</h1>
                    <div className="container py-5">
                        <div className="row py-3 px-3 px-sm-0">
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/chloroform.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Choloroform</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/methanol.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Methanol</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/toluene.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Toulene</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Xylene.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Xylene</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/white spirit.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">White Spirit</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Monoethylene.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Monothylene Glycol</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Diethylene Glycol.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Diethylene Glycol</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Paraffin Wax.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Paraffin Wax</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Citric Acid.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Citric Acid</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/LABSA.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">LABSA</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Acetic Acid.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Acetic Acid</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/carbon black.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Carbon Black</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default petrochemical
