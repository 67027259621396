import React from 'react'

function Minirals() {
    window.scrollTo(0, 0);
    return (
        <>
            <div>
                <section id="about-heading" className='d-flex align-items-center minerals-cover'>
                    <div className="container py-5">
                        <div className="row py-5 px-3 px-sm-0">
                            <div className='about-heading-content-box my-5'>
                                <h1 className='about-heading-head'>MINERALS</h1>
                                <p className='about-heading-para'>Powering various sectors with quality minerals and redefining your business's future.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="coal-detail">
                    <div className="container py-5">
                        <div className="row py-5 px-3 px-sm-0">
                            <div className="col-md-5 d-flex align-items-center mb-4 mb-md-0">
                                <div className="coal-img-box w-75 m-auto">
                                    <img src="assets/img/minerals main.webp" alt="Orion Energy" className='w-100 rounded-3 coal-detail-img' />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h3 className='coal-detail-heading mb-3'>About Minerals</h3>
                                <p className='coal-detail-para'>Orion Energy provides unparalleled quality and a diverse range of minerals. We source only the finest products, ensuring that each mineral in our collection has the finest quality. From rare ores to essential industrial commodities, we supply a spectrum of resources that exemplify our excellence. Orion Energy employs sustainable practices in processing minerals, guaranteeing a responsible and eco-friendly supply chain. We understand that different industries have distinct needs, which is why our minerals cater to sectors ranging from construction and manufacturing to energy.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="coal-type">
                    <h1 className='text-center coal-type-heading px-3 px-sm-0'>Minerals we Provide</h1>
                    <div className="container py-5">
                        <div className="row py-3 px-3 px-sm-0">
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/granite.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Granite</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/marble.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Marble</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/onyx copy.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Onyx</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/iron ore.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Iron Ore</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/copper ore.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Copper Ore</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/talc.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Soap Stone (Talc)</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/rockSalt.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Rock Salt</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Bauxite Ore.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-4">Bauxite Ore</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Quartz.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-3">Quartz</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center mb-4">
                                <div className="card" style={{ width: '22rem' }}>
                                    <div className="card-img-box overflow-hidden">
                                        <img src="assets/img/Calcium Carbonate.webp" className="card-img-top" alt="Orion Energy" />
                                    </div>
                                    <div className="card-body petrochemical-type text-center py-2">
                                        <h5 className="card-title fs-3">Calcium Carbonate</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Minirals
